<template>
  <v-container fluid>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-file-document-outline
      </v-icon> Document Types
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showForm = true"
        >
          Create Type
          <br>
        </v-btn>
      </v-col>
      <hr>
      <br>
      <div
        v-if="!isDocumentTypesAvailable"
        class="no-file-alert"
      >
        <h4>
          No document types found.
        </h4>
      </div>
      <div>
        <v-expansion-panels
          elevation
          color="red"
          hover
          popout
          accordion
        >
          <v-expansion-panel
            v-for="(item,i) in documentTypes"
            :key="i"
          >
            <v-expansion-panel-header class="my-name-style">
              {{ item.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p class="my-p-style">
                Type:
                <span class="font-my-style"> {{ item.type }}</span>
                <br> Description:
                <span class="my-description-style"> {{ item.description }}</span>
              </p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="py-3" />
      <div>
        <create-document-type
          v-if="showForm"
          @closed="showForm = false"
          @document-type-created="getDocumenyTypes()"
        />
      </div>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import CreateDocumentType from 'src/views/dashboard/settings/CreateDocumentTypes.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'DocumentTypes',
    components: {
      'create-document-type': CreateDocumentType,
      'centre-spinner': spinner,
      },
    data () {
      return {
        showForm: false,
        loading: false,
      };
    },
    computed: {
      documentTypes () {
        return this.$store.getters['featuresettings/getDocumentTypesList'];
      },
      isDocumentTypesAvailable () {
        if (this.documentTypes.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
     await this.getDocumenyTypes();
    },
    methods: {
      async getDocumenyTypes () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchDocumentTypes');
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-name-style {
  font-size: 16px;
  font-weight: bold;
  color:#37474F;
}
.my-description-style {
  font-weight: bold;
  color:#FF3700 !important;
}
.v-expansion-panels {
  z-index: 0;
}
.my-p-style {
    font-size: 16px;
    font-weight: bold;
    color: #546E7A;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-transform: capitalize;
}
.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
}
.no-file-alert {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 50px;
  margin-top: 50px;
}
</style>

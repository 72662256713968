<template>
  <v-container fluid>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-hard-hat-outline
      </v-icon> Contractor Categories
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showForm = true"
        >
          Add New
        </v-btn>
      </v-col>
      <hr>
      <div
        v-if="!isCategoriesAvailable"
        class="no-file-alert"
      >
        <h4>
          No contractor categories found.
        </h4>
      </div>
      <v-row
        align="center"
        justify="center"
        class="my-card-style"
      >
        <v-col>
          <v-card
            v-for="(item, index) in contractorCategoryList"
            :key="'item'+ index"
            class="card-style"
          >
            <v-list>
              <v-list-item-group>
                <template>
                  <v-list-item>
                    <v-row>
                      <v-col>
                        <v-list-item-title class="my-name-style">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="name-text">
                          {{ item.description }}
                        </v-list-item-subtitle>
                      </v-col>
                      <v-list-item-action>
                        <v-col>
                          <v-btn
                            icon
                            small
                            @click.stop
                            @click="toggleDocuments(index)"
                          >
                            <v-icon
                              v-if="!isExpanded"
                            >
                              mdi-arrow-down-drop-circle
                            </v-icon>
                            <v-icon v-if="isExpanded">
                              mdi-arrow-up-drop-circle
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-list-item-action>
                    </v-row>
                  </v-list-item>
                </template>
                <v-row v-if="selectedCardIndex === index">
                  <v-col
                    cols="12"
                  >
                    <v-list>
                      <v-list-item-group>
                        <v-list-item-title
                          class="document-type-style"
                          scrollable
                        >
                          <div class="divide-line-style" />
                          Document Types
                        </v-list-item-title>
                        <v-chip-group
                          column
                          multiple
                          class="pl-5"
                        >
                          <template v-for="doc in item.documents">
                            <v-chip
                              :key="doc.name"
                              :color="isDocMandatory(doc)"
                              outlined
                            >
                              {{ doc.name }}
                              <br><font class="type-style">
                                [ {{ doc.type }} Type ]
                              </font>
                            </v-chip>
                          </template>
                        </v-chip-group>
                      </v-list-item-group>
                    </v-list>
                    <font class="mandatory-Doc-style">
                      Mandatory documents are highlighted in red*.
                    </font>
                  </v-col>
                </v-row>
              </v-list-item-group>
            </v-list>
          </v-card>
          <div>
            <create-contractor-category
              v-if="showForm"
              @closed="showForm = false"
              @category-created="getContractorCategory()"
            />
          </div>
          <centre-spinner
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
  import CreateContractorCategory from 'src/views/dashboard/settings/CreateContractorCategory.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'ContractorCategories',
    components: {
      'create-contractor-category': CreateContractorCategory,
      'centre-spinner': spinner,
      },
    data () {
      return {
        showForm: false,
        loading: false,
        selectedCardIndex: null,
        isExpanded: false,
      };
    },
    computed: {
      contractorCategoryList () {
        return this.$store.getters['featuresettings/getContractorCategoryList'];
      },
      isCategoriesAvailable () {
        if (this.contractorCategoryList.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
     await this.getContractorCategory();
    },
    methods: {
      async getContractorCategory () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchContractorCategoryList');
        this.loading = false;
      },
      toggleDocuments (index) {
      if (this.selectedCardIndex === index) {
        this.selectedCardIndex = null;
      } else {
        this.selectedCardIndex = index;
      }
      this.expand();
      },
      expand () {
        this.isExpanded = !this.isExpanded;
      },
      isDocMandatory (doc) {
        if (doc.mandatory === true) {
          return '#C62828';
        }
        return '#37474f';
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-card-style {
  padding-left: 15px;
  padding-right: 15px;
}
.card-style {
  border-radius: 10px;
  color:"#b0bfb4";
}
.description-style {
  color: #020000;
}
.card-style, v-card v-sheet theme--light {
  margin-top: 15px !important;
  word-break: break-word;
  white-space: pre-wrap;
}
.document-type-style {
  margin-left: 20px !important;
  font-size: 16px;
  font-weight: bold;
  color:#37474f;
}
.name-text {
  word-break: break-word;
  white-space: pre-wrap;
  font-weight: bold;
  color:#FF3700 !important;
}
.my-name-style {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
  color:#37474F;
  word-break: break-word;
  white-space: pre-wrap;
}
.divide-line-style {
  border-top: 3px solid #CFD8DC;
  margin-bottom: 5px;
}
.mandatory-Doc-style {
  margin-left: 20px;
  font-size: 12px;
  font-style: italic;
  color: #37474F;
}
.no-file-alert {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 50px;
  margin-top: 50px;
}
.type-style {
  color:black;
  font-weight: bold;
  margin-left: 5px;
  text-transform: capitalize;
}
</style>

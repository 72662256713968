<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-file-document-edit-outline
        </v-icon>Document Type
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1 text-center">
            <v-row
              class="fill-height"
            >
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="name"
                  label="Document Name*"
                  dense
                  outlined
                  hint="Name for your reference-'High Risk Work'."
                  persistent-hint
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="type"
                  menu-props="auto"
                  :items="typeList"
                  label="Type*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <br>
            <v-textarea
              v-model="description"
              filled
              label="Descripion*"
              :rules="[(v) => !!v || 'This field is required']"
            />
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createDocumentType()"
            >
              create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'CreateDocumentType',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        name: '',
        description: null,
        loading: false,
        valid: false,
        typeList: [
          {
            name: 'Organization',
            id: 'organization',
          },
          {
            name: 'Contractor',
            id: 'contractor',
          },
          {
            name: 'Job',
            id: 'job',
          },
        ],
        type: '',
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      createDocumentType () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('featuresettings/createDocumentType', {
          name: this.name,
          description: this.description,
          type: this.type,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Document Type created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('document-type-created');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>

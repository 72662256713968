<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-file-question-outline
        </v-icon>Create Question
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card-text class="text-body-1">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="createData.name"
                  label="Question Name*"
                  dense
                  outlined
                  hint="Name is only for your internal reference."
                  persistent-hint
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="createData.type"
                  label="Type"
                  hint="Answer type - checkbox, normal form"
                  dense
                  outlined
                  disabled
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="createData.question"
                  label="Question*"
                  hint="Question for your reference - 'Are you COVID-19 vaccinated ?'"
                  outlined
                  dense
                  :rules="[(v) => !!v || 'This field is required']"
                  persistent-hint
                />
                <v-btn
                  color="#FF3700"
                  elevation="1"
                  small
                  align-start
                  class="my-btn-style"
                  @click="addAnswer()"
                >
                  Answer Options
                </v-btn>
              </v-col>
            </v-row>
            <p
              v-if="answerEntered"
              class="my-message"
            >
              A maximum of three answer options can be provided, click the above button to add more.
            </p>
            <br>
            <div
              v-for="(answer, index) in createData.answers"
              :key="index"
            >
              <div>
                <v-text-field
                  v-model="createData.answers[index].answer"
                  :label="answerLabel(index)"
                  hint="Answer to the above question."
                  outlined
                  dense
                  :rules="[(v) => !!v || 'Only three answers can be added.']"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      color="red darken-2"
                      @click="removeAnswer(index)"
                    >
                      mdi-close-circle-outline
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            depressed
            default
            @click="cancel()"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            depressed
            default
            @click="submit()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>

<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'CreateQuestion',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        createData: {
          name: null,
          question: null,
          type: 'checkbox',
          answers: [],
        },
        showModal: true,
        valid: false,
        loading: false,
      };
    },
    computed: {
      maxAnswersReached () {
        return this.createData.answers.length === 3;
      },
      answerEntered () {
        return this.createData.answers.length >= 1;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      cancel () {
        this.showModal = false;
      },
      async submit () {
        if (this.$refs.form.validate() === false || this.createData.answers.length === 0) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        await this.$store.dispatch('featuresettings/createQuestionnaire', {
          questionnaire: this.createData,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Questionnaire created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
      addAnswer () {
        if (this.maxAnswersReached) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Only three answers can be created.',
            type: Constants.ALERT_TYPE_ERROR,
          });
          return;
        }
        this.createData.answers.push({ answer: '' });
      },
      removeAnswer (index) {
        this.createData.answers.splice(index, 1);
      },
      answerLabel (index) {
        return 'Answer ' + (index + 1);
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-message {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  color: #c62828;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}
.my-btn-style {
  margin-top: 10px;
}
</style>

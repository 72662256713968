<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-switch
        </v-icon>Create Category
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1">
            <v-row
              class="fill-height"
            >
              <v-col
                cols="12"
                md="10"
              >
                <v-text-field
                  v-model="name"
                  label="Category Name*"
                  dense
                  outlined
                  hint="Name for your reference-'Plumber'."
                  persistent-hint
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
            </v-row>
            <br>
            <v-textarea
              v-model="description"
              filled
              label="Descripion*"
              :rules="[(v) => !!v || 'This field is required']"
            />
            <v-btn
              class="mt-n2 doc-btn-style"
              elevation="1"
              color="#37474F"
              small
              @click="showAddDocumentsform()"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Documnent Type
            </v-btn>
            <div
              v-if="selectedDocumentTypes.length > 0"
              class="my-chip-style"
            >
              <v-chip-group
                column
                multiple
                class="pl-5"
              >
                <template v-for="doc in selectedDocumentTypes">
                  <v-chip
                    :key="doc.name"
                    color="#37474F"
                    outlined
                  >
                    {{ doc.name }}
                  </v-chip>
                </template>
              </v-chip-group>
            </div>
            <p class="text-sm-left mt-5">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createContractorCategory()"
            >
              create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
    <add-document-type
      v-if="showAddDocumentsDialog"
      @selected-documents="addSelectedDocuments"
      @selected-type="showDocumentTypes"
      @closed="showAddDocumentsDialog=false"
    />
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import AddDocumentType from 'src/views/dashboard/settings/AddDocumentType.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'CreateContractorCategory',
    components: {
      'add-document-type': AddDocumentType,
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        name: '',
        description: null,
        showAddDocumentsDialog: false,
        selectedDocuments: {},
        valid: false,
        loading: false,
        selectedDocumentTypes: [],
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      this.$store.dispatch('featuresettings/clearSavedDocumentTypes');
      this.loading = false;
    },
    methods: {
      showAddDocumentsform () {
        this.showAddDocumentsDialog = true;
      },
      addSelectedDocuments (selectedDocuments) {
        this.selectedDocuments = selectedDocuments;
      },
      createContractorCategory () {
        if (this.$refs.form.validate() === false || this.selectedDocuments.length === 0) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('featuresettings/createContractorCategory', {
          name: this.name,
          description: this.description,
          documents: this.selectedDocuments,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Contractor Category created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('category-created');
          this.loading = false;
          this.showModal = false;
        }).catch(
            this.loading = false,
          );
      },
      showDocumentTypes (value) {
        this.selectedDocumentTypes = value;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.doc-btn-style {
  background-color: #FF3700 !important;
}
.my-chip-style {
  margin-top: 20px;
}
</style>
